import { ajax, getStoredAuthorization } from "@tm/utils"
import { WebserviceUrl } from "../../index"
import { SendEmailRequest } from "./models"

export async function sendEmail(body: SendEmailRequest) {
    const url = `${WebserviceUrl.RepairShops}/SendMail`
    const authorization = getStoredAuthorization()

    return ajax<Array<string>>({ url, body, authorization, method: "POST" })
}
