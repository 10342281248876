import { ajax, getStoredAuthorization, notUndefinedOrNull } from "@tm/utils"
import { GetRecommendedArticlesRequest, GetRecommendedArticlesResponse } from "./models"
import { Articles, WebserviceUrl } from "../../index"

export const getRecommendedArticles = (body: GetRecommendedArticlesRequest) => {
    const url = `${WebserviceUrl.Articles}/GetRecommendedArticles`
    const authorization = getStoredAuthorization()
    return ajax<GetRecommendedArticlesResponse>({ url, body, authorization, method: "POST" }).then((response) =>
        response?.articles ? response?.articles.map(Articles.mapArticle).filter(notUndefinedOrNull) : []
    )
}
