export * as Vouchers from "./vouchers"
export * as VeFiles from "./vefiles"
export * as FastService from "./fastService"
export * as Authority from "./authority"
export * as FastUp from "./fastup"
export * as ERIKContentService from "./erikContentService"
export * as RepairShops from "./repairShops"
export * as Articles from "./articles"

export const WebserviceUrl = {
    Vouchers: "/data/TM.Next.Vouchers",
    Vefiles: "/data/TM.Next.Vouchers/vefiles",
    Authority: "/data/TM.Next.Authority",
    RepairShops: "/data/TM.Next.RepairShops.Portal/RepairShops",
    FastService: "/data/TM.Next.FastCalculator/fastservice",
    Articles: "/data/TM.Next.Catalog/Articles",
}
